<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="购买属性名称">
              <el-input v-model="Name" placeholder="输入购买属性名称搜索" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select v-model="Active" placeholder="请选择有效性" clearable @change="handleSearch">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="showAddDialog" v-prevent-click>新增</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table
          :data="purchaseAttributesTableData"
          size="small"
          tooltip-effect="light"
          row-key="ID"
          :tree-props="{children: 'Child', hasChildren: 'hasChildren'}"
      >
        <el-table-column prop="Name" label="购买属性名称"></el-table-column>
        <el-table-column label="移动" min-width="180px">
          <template slot-scope="scope">
            <el-button
                size="small"
                type="primary"
                circle
                icon="el-icon-upload2"
                @click="upOneClick(scope.row,scope.$index)"
            ></el-button>
            <el-button
                size="small"
                type="primary"
                circle
                icon="el-icon-top"
                @click="upClick(scope.row,scope.$index)"
            ></el-button>
            <el-button
                size="small"
                type="primary"
                circle
                icon="el-icon-bottom"
                @click="downClick(scope.row,scope.$index)"
            ></el-button>
            <el-button
                size="small"
                type="primary"
                circle
                icon="el-icon-download"
                @click="downOneClick(scope.row,scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="有效性">
          <template slot-scope="scope">{{scope.row.Active?'是':'否'}}</template>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
                type="primary"
                size="small"
                @click="showEditDialog(scope.row)"
                v-prevent-click
            >编辑</el-button>
            <el-button
                type="primary"
                size="small"
                @click="addSubClassification(scope.row)"
                v-if="scope.row.ParentID == 0 && scope.row.Active"
                v-prevent-click
            >添加子分类</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--编辑、新增弹框-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
          size="small"
      >
        <el-form-item
            label="上级购买属性"
            v-if="ruleForm.parentName !='' && ruleForm.parentName != undefined"
        >
          <el-input v-model="ruleForm.parentName" :disabled="true">></el-input>
        </el-form-item>
        <el-form-item label="购买属性名称" prop="Name" v-if="whichDialog == '0'||whichDialog == '1'">
          <el-input v-model="ruleForm.Name" placeholder="请输入购买属性名称"></el-input>
        </el-form-item>
        <el-form-item label="购买属性名称" prop="classificationName" v-if="whichDialog == '2'">
          <el-input v-model="ruleForm.classificationName" placeholder="请输入分类名称"></el-input>
        </el-form-item>
        <el-form-item label="是否有效" prop="Active" v-if="whichDialog == '1'">
          <el-radio-group v-model="ruleForm.Active">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button
            type="primary"
            size="small"
            :loading="modalLoading"
            @click="submitForm('ruleForm')"
            v-prevent-click
        >保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/iBeauty/Basic/purchaseAttributes";

export default {
  name: "PurchaseAttributes",
  data() {
    return {
      loading: false,
      modalLoading: false,
      Name: "", // 搜索框值
      Active: true, // 有效性
      purchaseAttributesTableData: [], // 购买属性表格数据
      dialogVisible: false, // 编辑、新增弹出框状态
      whichDialog: "", // 判断弹出框类别
      ParentID:  0, // 父级ID
      NameParams: "", // 新增所传参数
      dialogTitle: "", // 弹框标题
      moveId: "", // 移动ID
      moveIndex: "",
      inputType: "",
      ruleForm: {
        Name: "", // 购买属性名称
        Active: true, // 是否有效
        ID: "", // 购买属性ID
        classificationName: "" // 分类名称
      }, // 编辑、新增表单数据
      rules: {
        Name: [{ required: true, message: "请输入购买属性名称", trigger: "blur" }],
        Active: [
          { required: true, message: "请选择是否有效", trigger: "change" }
        ],
        classificationName: [
          {
            required: true,
            message: "请输入购买属性名称",
            trigger: "change"
          }
        ]
      } // 编辑、新增表单规则验证
    };
  },
  methods: {
    // 搜索
    handleSearch() {
      var that = this;
      that.getPurchaseAttributes();
    },
    // 获取购买属性列表
    getPurchaseAttributes() {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active
      };
      API.getPurchaseAttributes(params)
          .then(res => {
            if (res.StateCode == 200) {
              that.purchaseAttributesTableData = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000
              });
            }
          })
          .finally(function() {
            that.loading = false;
          });
    },
    // 新增按钮点击事件
    showAddDialog() {
      var that = this;
      that.whichDialog = "0";
      that.ruleForm = {};
      that.ParentID =  0,
      that.inputType = "购买属性名称";
      that.dialogTitle = "新增购买属性";
      that.dialogVisible = true;
    },
    // 编辑
    showEditDialog(row) {
      var that = this;
      that.whichDialog = "1";
      that.inputType = "购买属性名称";
      that.dialogTitle = "编辑购买属性";
      that.ruleForm = Object.assign({}, row);
      that.purchaseAttributesTableData.forEach(val => {
        if (row.ParentID == val.ID) {
          that.ruleForm.parentName = val.Name;
        }
      });
      that.dialogVisible = true;
    },
    // 编辑、新增、添加子分类 保存按钮点击事件
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(valid => {
        if (valid) {
          if (that.whichDialog == "0") {
            that.NameParams = that.ruleForm.Name;
            that.createPurchaseAttributes();
          } else if (that.whichDialog == "1") {
            that.updatePurchaseAttributes();
          } else {
            that.NameParams = that.ruleForm.classificationName;
            that.createPurchaseAttributes();
          }
        }
      });
    },
    // 新增购买属性
    createPurchaseAttributes() {
      var that = this;
      that.modalLoading = true;
      var params = {
        ParentID: that.ParentID,
        Name: that.NameParams
      };
      API.createPurchaseAttributes(params)
          .then(res => {
            if (res.StateCode == 200) {
              that.$message.success("成功");
              that.dialogVisible = false;
              that.getPurchaseAttributes();
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000
              });
            }
          })
          .finally(function() {
            that.modalLoading = false;
          });
    },
    // 修改购买属性
    updatePurchaseAttributes() {
      var that = this;
      that.modalLoading = true;
      var params = {
        ID: that.ruleForm.ID,
        Name: that.ruleForm.Name,
        Active: that.ruleForm.Active
      };
      API.updatePurchaseAttributes(params)
          .then(res => {
            if (res.StateCode == 200) {
              that.$message.success("成功");
              that.dialogVisible = false;
              that.getPurchaseAttributes();
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000
              });
            }
          })
          .finally(function() {
            that.modalLoading = false;
          });
    },
    // 添加子分类点击事件
    addSubClassification(row) {
      var that = this;
      that.ParentID = row.ID;
      that.whichDialog = "2";
      that.ruleForm = {};
      that.inputType = "分类名称";
      that.dialogTitle = "购买属性-添加子分类";
      if (row.ParentID == 0) {
        that.ruleForm.parentName = row.Name;
      }
      that.dialogVisible = true;
    },
    // 递归
    setRecursion(data) {
      var that = this;
      var index = data.length;
      var moveId = "",
          beforeId = "";
      for (let i = 0; i <= data.length - 1; i++) {
        if (that.moveId == data[i].ID) {
          if (that.moveIndex == 0) {
            moveId = data[i].ID;
            beforeId = "";
          } else if (that.moveIndex == 1) {
            moveId = data[i].ID;
            if (i == 0 || i == 1) {
              beforeId = "";
            } else {
              beforeId = data[i - 2].ID;
            }
          } else if (that.moveIndex == 2) {
            moveId = data[i].ID;
            if (i == index - 1) {
              beforeId = data[i - 1].ID;
            } else {
              beforeId = data[i + 1].ID;
            }
          } else {
            moveId = data[i].ID;
            if (i == index - 1) {
              beforeId = data[i - 1].ID;
            } else {
              beforeId = data[index - 1].ID;
            }
          }
        }
      }
      that.movePurchaseAttributes(moveId, beforeId);
    },
    // 移动至顶部
    upOneClick(row) {
      var that = this;
      that.moveIndex = 0;
      that.moveId = row.ID;
      var purchaseAttributesList = [];
      if (row.ParentID == 0) {
        that.setRecursion(that.purchaseAttributesTableData);
      } else {
        that.purchaseAttributesTableData.forEach(val => {
          if (val.ID == row.ParentID) {
            purchaseAttributesList = val.Child;
          }
        });
        that.setRecursion(purchaseAttributesList);
      }
    },
    // 向上移动
    upClick(row) {
      var that = this;
      that.moveId = row.ID;
      that.moveIndex = 1;
      var purchaseAttributesList = [];
      if (row.ParentID == 0) {
        that.setRecursion(that.purchaseAttributesTableData);
      } else {
        that.purchaseAttributesTableData.forEach(val => {
          if (val.ID == row.ParentID) {
            purchaseAttributesList = val.Child;
          }
        });
        that.setRecursion(purchaseAttributesList);
      }
    },
    // 向下移动
    downClick(row) {
      var that = this;
      that.moveId = row.ID;
      that.moveIndex = 2;
      var purchaseAttributesList = [];
      if (row.ParentID != 0) {
        that.purchaseAttributesTableData.forEach(val => {
          if (val.ID == row.ParentID) {
            purchaseAttributesList = val.Child;
          }
        });
        that.setRecursion(purchaseAttributesList);
      } else {
        that.setRecursion(that.purchaseAttributesTableData);
      }
    },
    // 移动至底部
    downOneClick(row) {
      var that = this;
      that.moveId = row.ID;
      that.moveIndex = 3;
      var purchaseAttributesList = [];
      if (row.ParentID == 0) {
        that.setRecursion(that.purchaseAttributesTableData);
      } else {
        that.purchaseAttributesTableData.forEach(val => {
          if (val.ID == row.ParentID) {
            purchaseAttributesList = val.Child;
          }
        });
        that.setRecursion(purchaseAttributesList);
      }
    },
    // 移动购买属性
    movePurchaseAttributes(MoveID, BeforeID) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: MoveID,
        BeforeID: BeforeID
      };
      API.movePurchaseAttributes(params)
          .then(res => {
            if (res.StateCode == 200) {
              that.$message.success("成功");
              that.getPurchaseAttributes();
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000
              });
            }
          })
          .finally(function() {
            that.loading = false;
          });
    }
  },
  mounted() {
    var that = this;
    that.getPurchaseAttributes();
  }
};
</script>

<style scoped>
</style>